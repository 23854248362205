
























import { Component, Vue } from "vue-property-decorator";

import "@aws-amplify/ui-vue";
import { onAuthUIStateChange, AuthState } from "@aws-amplify/ui-components";

import Menu from "./components/Menu.vue";
import Footer from "./components/Footer.vue";

@Component({
  components: {
    "app-menu": Menu,
    "app-footer": Footer
  }
})
export default class App extends Vue {
  authState: AuthState | null = null;
  user: object | null = null;
  unsubscribeAuth: () => any = () => {};

  created() {
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData ? authData : null;
    });
  }

  beforeDestroy() {
    this.unsubscribeAuth();
  }
}

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vueHeadful from "vue-headful";
import VueCookies from "vue-cookies";

import "@aws-amplify/ui-components";

import Amplify from "aws-amplify";

Amplify.configure({
  Auth: {
    identityPoolId: process.env.VUE_APP_IDENTITY_POOL_ID,
    region: process.env.VUE_APP_USER_POOL_REGION,
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "3did",
        endpoint: process.env.VUE_APP_API_URL
      }
    ]
  }
});

import "./styles/index.scss";

Vue.config.productionTip = false;

Vue.component("vue-headful", vueHeadful);

Vue.use(VueCookies);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
